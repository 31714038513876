import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/joy';

export default function Logout() {
  const { logout } = useAuth0();

  return (
    <Button variant="outlined" color="primary" size="sm" onClick={() => logout({ logoutParams: { returnTo: window.location.origin + process.env.PUBLIC_URL } })}>
      Logout
    </Button>
  )
}