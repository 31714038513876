import React, { useState } from 'react';
import { Avatar, Box, Button, Chip, ColorPaletteProp, Divider, Grid, LinearProgress, List, ListItem, ListItemContent, ListItemDecorator, Modal, ModalClose, ModalDialog, Sheet, styled, Typography } from '@mui/joy';
import IRequestData from '../../types/request.type';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import tzDayJs from '../../services/tz-dayjs';

// Icons import
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

type LastRequestsProps = {
  requests: IRequestData[]
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-cell': {
    whiteSpace: 'break-spaces !important'
  }
}));

export default function LastRequests({ requests }: LastRequestsProps) {

  const [modalOpen, setModalOpen] = useState(false);
  const maxProcessedRequests = 5;
  const processedRequests = requests?.filter(x => x.status !== 0)?.slice(0, maxProcessedRequests) ?? [];

  const requestsItems = processedRequests.map((row) => {
    return <ListItem key={row.id} sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ListItemDecorator
          sx={row.id !== processedRequests[processedRequests.length - 1].id ? {
            '&:before': {
              content: '""',
              position: 'absolute',
              height: '100%',
              width: '2px',
              bgcolor: 'divider',
              left: 'calc(var(--List-item-paddingLeft) + 15px)',
              top: '50%',
            },
          } : {}}
        >
          <Avatar size="sm" />
        </ListItemDecorator>
        <ListItemContent>
          <Typography fontSize="sm">{row.quote}</Typography>
          <Typography level="body3" startDecorator={row.status === 1 ? <KeyboardArrowUpIcon color='success' /> : <KeyboardArrowDownIcon color='error' />}>
            {row.status === 1 ? 'Approved' : 'Rejected'}
          </Typography>
        </ListItemContent>
      </Box>
      <Box width={120} sx={{ textAlign: 'end' }}>
        <Typography level="body3">{tzDayJs(row.update_date).fromNow()}</Typography>
      </Box>
    </ListItem>
  });

  const columns: GridColumns = [
    { field: 'id', headerName: 'Id', width: 50 },
    {
      field: 'about',
      headerName: 'About',
      valueGetter: (params) => params.row.email,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Box>
          <Typography
            fontWeight="lg"
            level="body3"
            textColor="text.primary"
          >
            {params.row.full_name}
          </Typography>
          <Typography level="body3">{params.row.email}</Typography>
        </Box>
      }
    },
    { field: 'quote', headerName: 'Quote', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: (params) => params.row.status === 1 ? 'Approved' : params.row.status === -1 ? 'Rejected' : 'Pending',
      renderCell: (params: GridRenderCellParams<any>) => {
        const status = params.row.status === 1 ? 'Approved' : params.row.status === -1 ? 'Rejected' : 'Pending';
        return <Chip
          variant="soft"
          size="sm"
          startDecorator={
            {
              "Approved": <CheckIcon />,
              "Pending": <HourglassEmptyIcon />,
              "Rejected": <CloseIcon />
            }[status]
          }
          color={
            {
              "Approved": "success",
              "Pending": "neutral",
              "Rejected": "danger"
            }[status] as ColorPaletteProp
          }
        >
          {status}
        </Chip>
      }
    },
    {
      field: 'update_date',
      headerName: 'Date',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => tzDayJs(params.row.update_date).format('DD MMMM YYYY, hA')
    }
  ];

  const renderConfirmDialog = () => {
    return (
      <Modal sx={{ zIndex: 1200 }} open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          sx={{ width: '-webkit-fill-available' }}>
          <ModalClose />
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
          >
            All requests
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box
            sx={{ height: 500, width: 'auto', margin: '0 -20px' }}>
            <StyledDataGrid
              columns={columns}
              rows={requests}
              rowsPerPageOptions={[25, 50, 100]}
              disableSelectionOnClick
              pagination />
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  return (
    <>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: '8px',
          p: 2,
          listStyle: 'none',
          minWidth: 400,
          height: 'fit-content'
        }}>
        <Typography level='body2' endDecorator={<KeyboardArrowRightRoundedIcon fontSize='small' color='primary' />}>
          Last 5 requests
        </Typography>
        <List sx={{ '--List-decorator-size': '48px' }}>
          {requestsItems}
          {!processedRequests && <ListItem><LinearProgress /></ListItem>}
        </List>
        <Button
          size="sm"
          variant="solid"
          fullWidth
          endDecorator={<KeyboardArrowRightRoundedIcon fontSize="small" />}
          onClick={() => setModalOpen(true)}
          sx={{ px: 1, mt: 1 }}
        >
          See all
        </Button>
      </Sheet>
      {renderConfirmDialog()}
    </>
  );
}