import * as React from 'react';
import { Sheet, Typography } from '@mui/joy';

export default function DbOperations() {

  return (
    <Sheet
      sx={{ padding: 2, borderRadius: '12px' }}
    >
      <Typography>
        In development...
      </Typography>
    </Sheet>
  );
}
