import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, IconButton, Modal, ModalDialog, Sheet, Typography } from '@mui/joy';
import { Alert, AlertProps, Snackbar } from '@mui/material';
import { CustomTooltip } from '../tooltip/Tooltip';

// Services
import QuoteDataService from '../../services/quote.service';
import VersionDataService from '../../services/version.service';

// Icons import
import UpgradeIcon from '@mui/icons-material/Upgrade';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import IVersionData from '../../types/version.type';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useAuth0 } from '@auth0/auth0-react';

type VersionActionsProps = {
  versions: IVersionData[],
  refreshVersions: () => void
}

export default function VersionsActions({ versions, refreshVersions }: VersionActionsProps) {

  const { user } = useAuth0();
  const [currentQuotesCount, setCurrentQuotesCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);

  useEffect(() => {
    QuoteDataService.getCount()
      .then((response: any) => {
        setCurrentQuotesCount(response.data[0].numberOfQuotes);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }, []);

  const newQuotesCount = (currentQuotesCount > 0 && versions.length > 0) ? (currentQuotesCount - versions[0].to_count) : 0;

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleUpgradeClick = () => {
    setModalAction('upgrade');
    setOpenModal(true);
  }

  const handleDowngradeClick = () => {
    setModalAction('downgrade');
    setOpenModal(true);
  }

  const handleCancelModal = () => {
    setModalAction('');
    setOpenModal(false);
  }

  const handleConfirmModal = () => {
    if (versions.length === 0) {
      setSnackbar({ children: 'Error while fetching the previous versions. Contact the administrator.', severity: 'error' });
      return;
    }

    if (modalAction === 'upgrade') {
      const lastVersion = versions[0];

      const newVersion: IVersionData = {
        number: lastVersion.number + 1,
        from_count: lastVersion.to_count,
        to_count: currentQuotesCount,
        last_update: new Date(),
        updated_by: user?.name
      };
      VersionDataService.create(newVersion)
        .then((response: any) => {
          refreshVersions();
          setSnackbar({ children: 'Upgrade with success', severity: 'success' });
        })
        .catch((e: Error) => {
          setSnackbar({ children: `Some error occured: ${e.message}`, severity: 'error' });
          console.log(e.message);
        })
    } else if (modalAction === 'downgrade') {
      VersionDataService.deleteLast()
        .then((response: any) => {
          refreshVersions();
          setSnackbar({ children: 'Downgrade with success', severity: 'success' });
        })
        .catch((e: Error) => {
          setSnackbar({ children: `Some error occured: ${e.message}`, severity: 'error' });
          console.log(e.message);
        })
    }
    handleCancelModal();
  }

  const renderConfirmDialog = () => {
    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description">
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Confirmation
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to {modalAction} the version?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={handleCancelModal}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleConfirmModal}>
              Confirm
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  return (
    <>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: '8px',
          p: 2,
          gridColumn: '1/-1'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontSize="xs2"
            textColor="text.tertiary"
            textTransform="uppercase"
            letterSpacing="md"
            fontWeight="lg"
          >
            Set new version
          </Typography>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Button
              onClick={handleUpgradeClick}
              size="sm"
              variant="soft"
              sx={{ fontSize: 'xs', px: 1, mx: 1 }}
              endDecorator={<UpgradeIcon />}
            >
              Upgrade version
            </Button>
            <CustomTooltip message={'Downgrade'}>
              <IconButton
                onClick={handleDowngradeClick}
                size="sm"
                variant="soft"
                color="primary"
                sx={{ fontSize: 'xs', px: 1 }}
              >
                <PlayForWorkIcon />
              </IconButton>
            </CustomTooltip>
          </Box>
        </Box>
        <Typography>Based on previous version:</Typography>
        <Box sx={{ mt: 1.5, display: 'flex', gap: 1 }}>
          <Chip
            variant="outlined"
            color={(newQuotesCount > 0) ? "primary" : (newQuotesCount < 0) ? "danger" : "neutral"}
            size="sm"
            sx={{ borderRadius: 'sm' }}
          >
            {newQuotesCount > 0 ? `${newQuotesCount} new quote(s)` : newQuotesCount < 0 ? `${newQuotesCount} removed quotes` : 'No new quote(s)'}
          </Chip>
        </Box>
      </Sheet>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {renderConfirmDialog()}
    </>
  );
}