import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns, GridEventListener, GridRenderCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, GridToolbarContainer, MuiEvent } from '@mui/x-data-grid';
import QuoteDataService from '../../services/quote.service';
import { Box, Button, Divider, IconButton, Modal, ModalDialog, styled, Typography } from '@mui/joy';
import { Alert, AlertProps, Snackbar } from '@mui/material';
import IQuoteData from '../../types/quote.type';
import { CustomTooltip } from '../tooltip/Tooltip';

// Icons
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import tzDayJs from '../../services/tz-dayjs';

interface EditToolbarProps {
  setQuotes: (newQuotes: (oldQuotes: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  setPage: (newPage: number) => void;
  pageSize: number;
  quotes: any[];
}

function EditToolbar(props: EditToolbarProps) {
  const { setQuotes, setRowModesModel, setPage, pageSize, quotes } = props;

  const handleClick = () => {
    const lastId = quotes[quotes.length - 1].id;
    const id = lastId + 1;
    setPage(Math.ceil(quotes.length / pageSize));
    setQuotes((oldQuotes) => [...oldQuotes, { id, quote: '', favorites: 0, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'quote' },
    }));
  };

  const firstPage = () => {
    setPage(0);
  }

  const lastPage = () => {
    setPage(Math.ceil(quotes.length / pageSize));
  }

  const syncQuotes = () => {
    setQuotes((oldQuotes) => []);
    QuoteDataService.getAll()
      .then((response: any) => {
        setQuotes(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
      <CustomTooltip message={"First page"}>
        <IconButton size="sm" variant="plain" onClick={firstPage}>
          <FirstPageIcon />
        </IconButton>
      </CustomTooltip>
      <Button size="sm" variant="outlined" startDecorator={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <CustomTooltip message={"Last page"}>
        <IconButton size="sm" variant="plain" onClick={lastPage}>
          <LastPageIcon />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip message={"Synchronize the quotes"}>
        <IconButton size="sm" variant="plain" onClick={syncQuotes}>
          <PublishedWithChangesIcon />
        </IconButton>
      </CustomTooltip>
    </GridToolbarContainer>
  );
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === 'light' ? '#f4f4f4' : '#1c1c23',
  },
}));

export default function QuotesTable() {

  const mutateRow = useFakeMutation();
  const [quotes, setQuotes] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState<IQuoteData | null>(null);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);

  const columns: GridColumns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'quote', headerName: 'Quote', flex: 1, editable: true },
    { field: 'favorites', headerName: 'Favorites', type: 'number', width: 130 },
    {
      field: 'last_update',
      headerName: 'Last update',
      type: 'date',
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) => tzDayJs(params.row.last_update).format('DD MMMM YYYY, HH:mm:ss')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => {
        const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return (
            <>
              <IconButton size="sm" variant="plain" onClick={() => handleSaveClick(params.id)}>
                <SaveAsIcon />
              </IconButton>
              <IconButton size="sm" sx={{ ml: 1 }} variant="plain" onClick={() => handleCancelClick(params.id)}>
                <CancelIcon />
              </IconButton>
            </>
          );
        }
        return (
          <>
            <IconButton size="sm" variant="plain" onClick={() => handleEditClick(params.id)}>
              <DriveFileRenameOutlineIcon />
            </IconButton>
            <IconButton size="sm" sx={{ ml: 1 }} variant="plain" onClick={() => handleDeleteClick(params)}>
              <DeleteOutlineIcon />
            </IconButton>
          </>
        );
      }
    },
  ];

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = quotes.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setQuotes(quotes.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      const currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000);
      const row: IQuoteData = { id: newRow.id, quote: newRow.quote, favorites: newRow.favorites, last_update: currentDate };
      newRow.last_update = currentDate;
      if (newRow.isNew) {
        QuoteDataService.create(row)
          .then((response: any) => {
            setSnackbar({ children: 'Quote successfully added', severity: 'success' });
          })
          .catch((e: Error) => {
            setSnackbar({ children: e.message, severity: 'error' });
          });
      } else {
        QuoteDataService.update({ obj: row })
          .then((response: any) => {
            setSnackbar({ children: 'Quote successfully updated', severity: 'success' });
          })
          .catch((e: Error) => {
            setSnackbar({ children: e.message, severity: 'error' });
          });
      }

      const response = await mutateRow(newRow);
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleDeleteClick = (params: GridRenderCellParams<any>) => {
    setSelectedRow(params.row);
    setOpenDeleteRow(true);
  }

  const handleDeleteNo = () => {
    setOpenDeleteRow(false);
    setSelectedRow(null);
  };

  const handleDeleteYes = () => {
    QuoteDataService.delete(selectedRow!.id)
      .then((response: any) => {
        setSnackbar({ children: `Quote '${selectedRow!.quote}' successfully removed`, severity: 'success' });
        refreshQuotes();
      })
      .catch((e: Error) => {
        setSnackbar({ children: e.message, severity: 'error' });
      });
    setOpenDeleteRow(false);
    setSelectedRow(null);
  };

  const renderConfirmDialog = () => {
    return (
      <Modal open={openDeleteRow} onClose={() => setOpenDeleteRow(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description">
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Confirmation
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to delete quote '<strong>{selectedRow?.quote}</strong>'?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={handleDeleteNo}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={handleDeleteYes}>
              Delete
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  const refreshQuotes = () => {
    QuoteDataService.getAll()
      .then((response: any) => {
        setQuotes(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }

  useEffect(() => {
    refreshQuotes();
  }, []);

  return (
    <>
      <StyledDataGrid
        autoHeight
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        rows={quotes}
        columns={columns}
        loading={!quotes.length}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 50, 100]}
        checkboxSelection
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{
          Toolbar: EditToolbar
        }}
        componentsProps={{
          toolbar: { setQuotes, setRowModesModel, setPage, pageSize, quotes },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {renderConfirmDialog()}
    </>
  );
}

const useFakeMutation = () => {
  return React.useCallback(
    (row: Partial<IQuoteData>) =>
      new Promise<Partial<IQuoteData>>((resolve, reject) =>
        setTimeout(() => {
          if (row.quote?.trim() === '') {
            reject(new Error("Error while saving the quote: the input can't be empty."));
          } else {
            resolve(row);
          }
        }, 200),
      ),
    [],
  );
};