import React from "react";
import { Tooltip } from "@mui/joy";

type Props = {
    message?: String
    children: any
}

export const CustomTooltip = (props: Props) => {
    return (
        <Tooltip title={props.message} size="sm" variant="outlined">
            {props.children}
        </Tooltip>
    );
}