import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import { Badge, Button } from '@mui/joy';
import { useAuth0 } from '@auth0/auth0-react';

// Icons import
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

// custom
import emailTheme from './theme';
import Menu from './components/menu/Menu';
import Layout from './components/layout/Layout';
import Navigation from './components/navigation/Navigation';
import Home from './pages/home/Home';
import Login from './components/login/Login';
import Logout from './components/login/Logout';
import QuotesManager from './pages/quotes/QuotesManager';
import VersionManager from './pages/versions/VersionManager';
import RequestsManager from './pages/requests/RequestsManager';
import DbOperations from './pages/db-operations/DbOperations';
import { CustomTooltip } from './components/tooltip/Tooltip';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="primary"
      onClick={() => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
      }}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { isAuthenticated, user } = useAuth0();

  return (

    <CssVarsProvider disableTransitionOnChange theme={emailTheme}>
      <CssBaseline />
      {!isAuthenticated ?
        <Login /> :
        <>
          <Router>
            {drawerOpen && (
              <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                <Navigation />
              </Layout.SideDrawer>
            )}
            <Layout.Root
              sx={{
                ...(drawerOpen && {
                  height: '100vh',
                  overflow: 'hidden',
                }),
              }}
            >
              <Layout.Header>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1.5,
                  }}
                >
                  <IconButton
                    variant="outlined"
                    size="sm"
                    onClick={() => setDrawerOpen(true)}
                    sx={{ display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    variant="solid"
                    sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                  >
                    <GroupIcon />
                  </IconButton>
                  <Typography component="h1" fontWeight="xl">
                    Dashboard
                  </Typography>
                </Box>
                <Input
                  size="sm"
                  placeholder="Search anything…"
                  startDecorator={<SearchRoundedIcon color="primary" />}
                  endDecorator={
                    <IconButton variant="outlined" size="sm" color="neutral">
                      <Typography fontWeight="lg" fontSize="sm" textColor="text.tertiary">
                        /
                      </Typography>
                    </IconButton>
                  }
                  sx={{
                    flexBasis: '500px',
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
                  <CustomTooltip message={user?.email}>
                    <Button variant="outlined" color="primary" size="sm">
                      {user?.name}
                    </Button>
                  </CustomTooltip>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    color="primary"
                    sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                  >
                    <SearchRoundedIcon />
                  </IconButton>
                  <Menu
                    id="notifications-items"
                    control={
                      <Badge>
                        <IconButton
                          size="sm"
                          variant="outlined"
                          color="primary"
                          aria-label="Apps"
                        >
                          <NotificationsNoneIcon />
                        </IconButton>
                      </Badge>
                    }
                    menus={[
                      {
                        label: 'Notification 1'
                      },
                      {
                        label: 'Notification 2'
                      }
                    ]}
                  />
                  <Menu
                    id="app-selector"
                    control={
                      <IconButton
                        size="sm"
                        variant="outlined"
                        color="primary"
                        aria-label="Apps"
                      >
                        <GridViewRoundedIcon />
                      </IconButton>
                    }
                    menus={[
                      {
                        label: 'Dashboard',
                        active: true,
                        href: '/',
                        'aria-current': 'page',
                      },
                      {
                        label: 'In progress',
                        href: '/in-progress',
                      }
                    ]}
                  />
                  <ColorSchemeToggle />
                  <Logout />
                </Box>
              </Layout.Header>
              <Layout.SideNav>
                <Navigation />
              </Layout.SideNav>
              <Layout.Main>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/version' element={<VersionManager />} />
                  <Route path='/quotes' element={<QuotesManager />} />
                  <Route path='/requests' element={<RequestsManager />} />
                  <Route path='/db-operations' element={<DbOperations />} />
                </Routes>
              </Layout.Main>
            </Layout.Root>
          </Router>
        </>
      }
    </CssVarsProvider>
  );
}