import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Box, Button, Card, IconButton, Typography } from '@mui/joy';

// Icons import
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type CardProps = {
  icon: JSX.Element,
  mainText: string,
  countItems: number | string,
  showBadge: boolean,
  redirectPath?: string,
  isExternalPath?: boolean
}

export default function StatisticCard({ icon, mainText, countItems, showBadge, redirectPath, isExternalPath }: CardProps) {

  const navigate = useNavigate();

  const navigateToThere = () => {
    if (isExternalPath)
    {
      window.location.replace(redirectPath!);
      return null;
    }
    navigate(redirectPath!);
  }

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, maxWidth: { md: 300 }, boxShadow: 'md' }}>
      <Badge invisible={!showBadge} size='sm' sx={{ width: 'fit-content' }}>
        <IconButton
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{ width: 'fit-content' }}
        >
          {icon}
        </IconButton>
      </Badge>
      <Box>
        <Typography fontSize="xl3" fontWeight="xl">
          {countItems}
        </Typography>
        <Typography fontSize="xl1" color='neutral'>
          {mainText}
        </Typography>
      </Box>
      {redirectPath &&
        <Button onClick={navigateToThere} variant="solid" endDecorator={<KeyboardArrowRightIcon />}>
          See more
        </Button>
      }
    </Card>
  )
}