import React, { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import RequestDataService from '../../services/request.service';
import IRequestData from "../../types/request.type";
import PendingRequestsTable from "../../components/requests/PendingRequestsTable";
import LastRequests from "../../components/requests/LastRequests";

export default function RequestsManager() {

  const [requests, setRequests] = useState<IRequestData[]>([]);

  const refreshRequests = () => {
    RequestDataService.getAll()
      .then((response: any) => {
        setRequests(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }

  useEffect(() => {
    refreshRequests();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: {
            sm: 'column',
            md: 'row',
          },
        }}
      >
        <PendingRequestsTable requests={requests} refreshRequests={refreshRequests} />
        <LastRequests requests={requests} />
      </Box>
    </>
  )
}