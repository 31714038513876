import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, IconButton, IconButtonProps, Typography, useColorScheme } from '@mui/joy';

// Icons import
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

function ColorSchemeToggle({ onClick, ...props }: IconButtonProps) {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="plain" color="neutral" disabled />;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="plain"
      color="neutral"
      {...props}
      onClick={(event) => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
        onClick?.(event);
      }}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function Login() {
  const { error, loginWithRedirect, logout } = useAuth0();

  return (
    <Box
      sx={(theme) => ({
        width:
          'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
        transition: 'width var(--Transition-duration)',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255 255 255 / 0.6)',
        [theme.getColorSchemeSelector('dark')]: {
          backgroundColor: 'rgba(19 19 24 / 0.4)',
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
          width:
            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
          maxWidth: '100%',
          px: 2,
        }}
      >
        <Box
          component="header"
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontWeight="lg"
            startDecorator={
              <Box
                component="span"
                sx={{
                  width: 24,
                  height: 24,
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
                  borderRadius: '50%',
                  boxShadow: (theme) => theme.shadow.md,
                  '--joy-shadowChannel': (theme) =>
                    theme.vars.palette.primary.mainChannel,
                }}
              />
            }
          >
            Miau Dashboard
          </Typography>
          <ColorSchemeToggle />
        </Box>
        <Box
          component="main"
          sx={{
            my: 'auto',
            py: 2,
            pb: 5,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 400,
            maxWidth: '100%',
            mx: 'auto',
            borderRadius: 'sm'
          }}
        >
          <div>
            <Typography component="h2" fontSize="xl2" fontWeight="lg">
              Welcome back
            </Typography>
            <Typography level="body2" sx={{ my: 1, mb: 3 }}>
              Let&apos;s get started! Please login to the system.
            </Typography>
          </div>
          {!error && (
            <Button fullWidth onClick={() => loginWithRedirect()}>
              Sign in
            </Button>
          )}
          {error && (
            <Button variant="outlined" color="neutral" fullWidth startDecorator={<LogoutIcon />} onClick={() => logout({ logoutParams: { returnTo: window.location.origin + process.env.PUBLIC_URL } })}>
              You're not authorized
            </Button>
          )}
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
          <Typography level="body3" textAlign="center">
            © Unway {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}