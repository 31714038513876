import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/joy/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const publicUrl = process.env.PUBLIC_URL;

ReactDOM.createRoot(document.querySelector("#root") as Element).render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain!}
      clientId={clientId!}
      authorizationParams={{
        redirect_uri: window.location.origin + publicUrl
      }}
    >
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </Auth0Provider>
  </React.StrictMode>
);