import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Sheet, Typography } from '@mui/joy';
import StatisticsDataService from '../../services/statistics.service';
import IStatisticsData from '../../types/statistics.type';
import StatisticCard from '../../components/home/StatisticCard';
import WelcomeCard from '../../components/home/WelcomeCard';

// Icons import
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ArticleIcon from '@mui/icons-material/Article';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import AndroidIcon from '@mui/icons-material/Android';

export default function Home() {

  const [statistics, setStatistics] = useState<IStatisticsData>({} as IStatisticsData);
  const [googlePlayStatistics, setGooglePlayStatistics] = useState('');

  const refreshStatistics = () => {
    StatisticsDataService.get()
      .then((response: any) => {
        setStatistics(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }
  const refreshGooglePlayStatistic = () => {
    StatisticsDataService.getGooglePlay()
      .then((response: any) => {
        setGooglePlayStatistics(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }

  useEffect(() => {
    refreshStatistics();
    refreshGooglePlayStatistic();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: {
          xs: 'column', md: 'row'
        }
      }}
    >
      <WelcomeCard />
      <StatisticCard icon={<ArticleIcon />} mainText='Total quotes' countItems={statistics.totalQuotes} showBadge={false} redirectPath='/quotes' />
      <StatisticCard icon={<UpcomingIcon />} mainText='Open requests' countItems={statistics.totalRequests} showBadge={statistics.totalRequests > 0} redirectPath='/requests' />
      <StatisticCard icon={<MoveUpIcon />} mainText='Current version' countItems={statistics.currentVersion} showBadge={false} redirectPath='/version' />

      <StatisticCard icon={<AndroidIcon />} mainText='GPlay downloads' countItems={googlePlayStatistics} showBadge={false} redirectPath='https://play.google.com/store/apps/details?id=com.vanillapps.miau' isExternalPath />
    </Box>
  );
}
