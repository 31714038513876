import { LinearProgress, Sheet, Table, Typography } from '@mui/joy';
import React from 'react';
import IVersionData from '../../types/version.type';
import tzDayJs from '../../services/tz-dayjs';

// Icons import
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

type VersionTableProps = {
  versions: IVersionData[]
}

export default function VersionsTable({ versions }: VersionTableProps) {

  return (
    <Sheet
      variant="outlined"
      sx={{ borderRadius: '8px', gridColumn: '1/-1' }}>
      <Table sx={{ '--Table-headerUnderlineThickness': '1px', 'th, td': { p: 2 } }}>
        <thead>
          <tr>
            <th>
              <Typography level="body3" fontWeight="md" noWrap>Version</Typography>
            </th>
            <th>
              <Typography level="body3" fontWeight="md" noWrap>From quotes</Typography>
            </th>
            <th>
              <Typography level="body3" fontWeight="md" noWrap>To quotes</Typography>
            </th>
            <th>
              <Typography level="body3" fontWeight="md" noWrap>Date updated</Typography>
            </th>
            <th>
              <Typography level="body3" fontWeight="md" noWrap>Updated by</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {versions ? versions.map((row) => (
            <tr key={row.id}>
              <td>
                <Typography
                  level="body2"
                  endDecorator={row.id === versions[0].id ? <CloudDoneIcon color="primary" /> : <></>}
                  sx={{ alignItems: 'flex-start' }}
                >
                  {row.number}
                </Typography>
              </td>
              <td>
                <Typography level="body2" sx={{ color: 'danger.700' }}>
                  {row.from_count}
                </Typography>
              </td>
              <td>
                <Typography level="body2" sx={{ color: 'success.600' }}>
                  {row.to_count}
                </Typography>
              </td>
              <td>
                <Typography level="body2">
                  {tzDayJs(row.last_update).format('DD MMMM YYYY, hA')}
                </Typography>
              </td>
              <td>
                <Typography
                  level="body2"
                  startDecorator={<PersonOutlineIcon color="primary" />}
                  sx={{ alignItems: 'flex-start' }}
                >
                  {row.updated_by}
                </Typography>
              </td>
            </tr>
          )) : <LinearProgress />}
        </tbody>
      </Table>
    </Sheet>
  );
}