import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chip } from '@mui/joy';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import StatisticsDataService from '../../services/statistics.service';

// Icons import
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ArticleIcon from '@mui/icons-material/Article';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import StorageIcon from '@mui/icons-material/Storage';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import IStatisticsData from '../../types/statistics.type';

function ProjectsNavigation() {
  const [open, setOpen] = React.useState(true);

  return (
    <ListItem nested sx={{ mt: 2 }}>
      <ListSubheader>
        Projects
        <IconButton
          size="sm"
          variant="plain"
          color="primary"
          sx={{ '--IconButton-size': '24px', ml: 'auto' }}
          onClick={() => setOpen(!open)}
        >
          <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" sx={{
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }} />
        </IconButton>
      </ListSubheader>
      {open && (
        <List
          aria-labelledby="nav-list-tags"
          size="sm"
          sx={{
            '--List-decorator-size': '32px',
            '& .JoyListItemButton-root': { p: '8px' },
          }}
        >
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'primary.300',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Miau</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'danger.300',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Infuse</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'warning.200',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Diko Paints</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'success.300',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Soul - Fleurs Pour Toujours</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </ListItem>
  );
}

function DashboardNavigation() {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const routeChange = (path: string) => {
    navigate(path);
  }

  const [statistics, setStatistics] = useState<IStatisticsData>({} as IStatisticsData);

  const refreshStatistics = () => {
    StatisticsDataService.get()
      .then((response: any) => {
        setStatistics(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }

  useEffect(() => {
    refreshStatistics();
  }, []);

  return (
    <ListItem nested>
      <ListSubheader>
        Dashboard
        <IconButton
          size="sm"
          variant="plain"
          color="primary"
          sx={{ '--IconButton-size': '24px', ml: 'auto' }}
          onClick={() => setOpen(!open)}
        >
          <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" sx={{
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }} />
        </IconButton>
      </ListSubheader>
      {open && (
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            '& .JoyListItemButton-root': { p: '8px' },
            '--List-gap': '4px'
          }}
        >
          <ListItem>
            <ListItemButton onClick={() => routeChange('/')} variant={location.pathname === '/' ? "soft" : "plain"} color={location.pathname === '/' ? "primary" : "neutral"}>
              <ListItemDecorator sx={{ color: location.pathname == '/' ? 'inherit' : 'neutral.500' }}>
                <LaptopMacIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Main</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => routeChange('/version')} variant={location.pathname === '/version' ? "soft" : "plain"} color={location.pathname === '/version' ? "primary" : "neutral"}>
              <ListItemDecorator sx={{ color: location.pathname == '/version' ? 'inherit' : 'neutral.500' }}>
                <MoveUpIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Version changer</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => routeChange('/quotes')} variant={location.pathname === '/quotes' ? "soft" : "plain"} color={location.pathname === '/quotes' ? "primary" : "neutral"}>
              <ListItemDecorator sx={{ color: location.pathname == '/quotes' ? 'inherit' : 'neutral.500' }}>
                <ArticleIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Quotes manager</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => routeChange('/requests')} variant={location.pathname === '/requests' ? "soft" : "plain"} color={location.pathname === '/requests' ? "primary" : "neutral"}>
              <ListItemDecorator sx={{ color: location.pathname == '/requests' ? 'inherit' : 'neutral.500' }}>
                <UpcomingIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Requests</ListItemContent>
              {statistics &&
                <Chip color={'primary'} variant="soft" size="sm">
                  {statistics.totalRequests}
                </Chip>
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => routeChange('/db-operations')} variant={location.pathname === '/db-operations' ? "soft" : "plain"} color={location.pathname === '/db-operations' ? "primary" : "neutral"}>
              <ListItemDecorator sx={{ color: location.pathname == '/db-operations' ? 'inherit' : 'neutral.500' }}>
                <StorageIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Database operations</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </ListItem>
  );
}

export default function Navigation() {
  return (
    <List size="sm" sx={{ '--List-item-radius': '8px' }}>
      <DashboardNavigation />
      <ProjectsNavigation />
    </List>
  );
}
