import React, { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import VersionDataService from '../../services/version.service';
import VersionsActions from "../../components/versions/VersionsActions";
import VersionsTable from "../../components/versions/VersionsTable";
import IVersionData from "../../types/version.type";

export default function VersionManager() {

  const [versions, setVersions] = useState<IVersionData[]>([]);

  const refreshVersions = () => {
    VersionDataService.getAll()
      .then((response: any) => {
        setVersions(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
  }

  useEffect(() => {
    refreshVersions();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          gap: 2,
        }}
      >
        <VersionsActions refreshVersions={refreshVersions} versions={versions} />
        <VersionsTable versions={versions} />
      </Box>
    </>
  )
}