import React from 'react';
import { Box, Button, Sheet, Typography } from '@mui/joy';

// Icons import
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function WelcomeCard() {

  return (
    <Sheet
      variant="solid"
      color={'primary'}
      invertedColors
      sx={{
        flexGrow: 1,
        display: 'flex',
        bgcolor: '#042449',
        py: { xs: '36px', md: '20px' },
        px: { xs: '36px', md: '32px' },
        borderRadius: '12px',
        overflow: 'hidden',
        '& button': { borderRadius: 'xl' },
      }}
    >
      <Box sx={{ zIndex: 1, position: 'relative' }}>
        <Typography level="h1" fontWeight={700}>Welcome</Typography>
        <Typography sx={{ mt: 1, mb: 3 }}>
          Instant access to the Miau Dashboard
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            maxWidth: 'max-content',
            '& > *': { flexGrow: 1, fontWeight: 'lg' },
          }}
        >
          <Button
            variant="plain"
            endDecorator={<ArrowForwardIcon fontSize="medium" />}
            sx={{
              '&:hover': { '--Button-gap': '0.75rem' },
              '& span': { transition: '0.3s' },
            }}
          >
            Hoooooray
          </Button>
        </Box>
      </Box>
      <Box
        component="img"
        alt=""
        src="https://storage.googleapis.com/cms-storage-bucket/72521e62275b24d3c37d.png"
        sx={{ position: 'absolute', height: '100%', top: 0, right: 0 }}
      />
    </Sheet>
  )
}