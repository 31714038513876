import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';

// Icons import
import QuotesTable from '../../components/quotes/QuotesTable';

export default function QuotesManager() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%'
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: '8px',
          width: '100%'
        }}
      >
        <QuotesTable />
      </Sheet>
    </Box>
  );
}
