import React, { useState } from 'react';
import { Box, Button, Chip, Divider, IconButton, LinearProgress, Modal, ModalDialog, Sheet, Table, Typography } from '@mui/joy';
import { CustomTooltip } from '../tooltip/Tooltip';
import { Alert, AlertProps, Snackbar, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IRequestData from '../../types/request.type';
import RequestDataService from '../../services/request.service';
import FormDataService from '../../services/form.service';
import tzDayJs from '../../services/tz-dayjs';

// Icons import
import PersonIcon from '@mui/icons-material/Person';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import TagIcon from '@mui/icons-material/Tag';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

type PendingRequestsTableProps = {
  requests: IRequestData[],
  refreshRequests: () => void
}

export default function PendingRequestsTable({ requests, refreshRequests }: PendingRequestsTableProps) {

  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [modalRequest, setModalRequest] = useState<IRequestData | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);
  const pendingRequests = requests?.filter(x => x.status === 0) ?? [];

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleApproveClick = (request: IRequestData) => {
    setModalRequest(request);
    setModalAction('approve');
    setOpenModal(true);
  }

  const handleRejectClick = (request: IRequestData) => {
    setModalRequest(request);
    setModalAction('reject');
    setOpenModal(true);
  }

  const handleCancelModal = () => {
    setOpenModal(false);
    setModalRequest(null);
    setModalAction('');
  }

  const handleConfirmModal = () => {
    var localAction = '';
    if (modalAction === 'approve') {
      modalRequest!.status = 1;
      localAction = 'Approved';
    } else if (modalAction === 'reject') {
      modalRequest!.status = -1;
      localAction = 'Rejected';
    }
    modalRequest!.update_date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000);
    RequestDataService.updateStatus(modalRequest!)
      .then((response: any) => {
        refreshRequests();
        setSnackbar({ children: `${localAction} with success`, severity: 'success' });
        sendMailTo(modalRequest!);
      })
      .catch((e: Error) => {
        setSnackbar({ children: `Some error occured: ${e.message}`, severity: 'error' });
        console.log(e.message);
      })

    handleCancelModal();
  }

  const sendMailTo = (request: IRequestData) => {
    FormDataService.create(request)
      .then((respons: any) => {
        console.log("Message sent");
      })
      .catch((e: Error) => {
        setSnackbar({ children: `Some error occured: ${e.message}`, severity: 'error' });
        console.log(e.message);
      })
  }

  const renderConfirmDialog = () => {
    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description">
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Confirmation
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Are you sure you want to {modalAction} the following quote '<b>{modalRequest?.quote}</b>'?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={handleCancelModal}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleConfirmModal}>
              Confirm
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  return (
    <>
      <Sheet
        variant="outlined"
        sx={{ borderRadius: '8px', gridColumn: '1/-1' }}>
        <Table sx={{ '--Table-headerUnderlineThickness': '1px', 'th, td': { p: 2 } }}>
          <TableHead>
            <TableRow>
              <TableCell width={50}>
                <Typography level="body3" fontWeight="md" noWrap>Id</Typography>
              </TableCell>
              <TableCell width={150}>
                <Typography level="body3" fontWeight="md" noWrap>About</Typography>
              </TableCell>
              <TableCell>
                <Typography level="body3" fontWeight="md" noWrap>Quote</Typography>
              </TableCell>
              <TableCell width={200}>
                <Typography level="body3" fontWeight="md" noWrap>Date</Typography>
              </TableCell>
              <TableCell width={120}>
                <Typography level="body3" fontWeight="md" noWrap>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingRequests ? pendingRequests.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography level="body2">
                    {row.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                  }}>
                    {row.full_name &&
                      <CustomTooltip message={row.full_name}>
                        <Chip size='sm' color='neutral' variant='outlined' startDecorator={<PersonIcon />}>
                          {row.full_name}
                        </Chip>
                      </CustomTooltip>
                    }
                    {row.instagram &&
                      <CustomTooltip message={row.instagram}>
                        <Chip size='sm' color='neutral' variant='outlined' startDecorator={<InstagramIcon />}>
                          {row.instagram}
                        </Chip>
                      </CustomTooltip>
                    }
                    {row.email &&
                      <CustomTooltip message={row.email}>
                        <Chip size='sm' color='neutral' variant='outlined' startDecorator={<EmailIcon />}>
                          {row.email}
                        </Chip>
                      </CustomTooltip>
                    }
                    {row.hashtag &&
                      <CustomTooltip message={row.hashtag}>
                        <Chip size='sm' color='neutral' variant='outlined' startDecorator={<TagIcon />}>
                          {row.hashtag}
                        </Chip>
                      </CustomTooltip>
                    }
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography level="body2">
                    {row.quote}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography level="body2">
                    {tzDayJs(row.update_date).format('DD MMMM YYYY, hA')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1
                    }}
                  >
                    <CustomTooltip message={"Approve"}>
                      <IconButton size="sm" variant="soft" onClick={() => handleApproveClick(row)}>
                        <BookmarkAddedIcon />
                      </IconButton>
                    </CustomTooltip>
                    <CustomTooltip message={"Reject"}>
                      <IconButton size="sm" variant="soft" onClick={() => handleRejectClick(row)}>
                        <BookmarkRemoveIcon />
                      </IconButton>
                    </CustomTooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )) : <LinearProgress />}
          </TableBody>
        </Table>
      </Sheet>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {renderConfirmDialog()}
    </>
  );
}