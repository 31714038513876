import http from "./http-common";
import IVersionData from "../types/version.type"

class VersionDataService {
  get() {
    return http.get<IVersionData>("/version");
  }

  getAll() {
    return http.get<Array<IVersionData>>("/version/all");
  }

  create(data: IVersionData) {
    return http.post<IVersionData>("/version", data);
  }

  deleteLast() {
    return http.delete<any>('/version/last');
  }
}

export default new VersionDataService();