import http from "./http-common";
import IStatisticsData from "../types/statistics.type"

class StatisticsDataService {
  get() {
    return http.get<IStatisticsData>("/statistics");
  }
  getGooglePlay() {
    return http.get<any>("/google-play/downloads");
  }
}

export default new StatisticsDataService();