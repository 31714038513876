import http from "./http-common";
import IQuoteData from "../types/quote.type"

class QuoteDataService {
  getAll() {
    return http.get<Array<IQuoteData>>("/quotes");
  }

  getCount() {
    return http.get<number>("/quotes/count");
  }

  create(data: IQuoteData) {
    return http.post<IQuoteData>("/quotes", data);
  }

  createBulk(data: IQuoteData) {
    return http.post<IQuoteData>("/quotes/bulk", data);
  }

  update(data: any) {
    return http.patch<any>("/quote", data);
  }

  delete(id: any) {
    return http.delete<any>(`/quote/${id}`);
  }
}

export default new QuoteDataService();