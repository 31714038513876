import http from "./http-common";
import IRequestData from "../types/request.type"

class RequestDataService {
  getAll() {
    return http.get<Array<IRequestData>>("/requests");
  }

  updateStatus(data: IRequestData) {
    return http.patch<IRequestData>("/request", data);
  }

  create(data: IRequestData) {
    return http.post<IRequestData>("/request", data);
  }
}

export default new RequestDataService();